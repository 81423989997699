/**
    Protocol slide: list of questions.
*/
<template>
    <h1 class="slide-header">{{ slide.title }}</h1>
    <h3>{{ slide.subtitle }}</h3>
    <div class="slide-body">
        <ul class="slide-questions">
            <li v-for="(question, index) in slide.questions" :key="index">
                {{ question }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        slide: Object
    }
}
</script>
