/**
    Contains a title slide for a protocol.
*/
<template>
    <h1>{{ slide.heading }}</h1>
    <div
        v-for="(section, index) in slide.content" :key="`${index}_${slide.index}`"
        class="header-card"
    >
        <div class="header-card-title">{{ section.title }}</div>
        <div class="header-card-body">{{ section.body }}</div>
    </div>
</template>

<script>
export default {
    props: {
        slide: Object
    }
}
</script>

<style lang="scss">
.header-card {
    max-width: 600px;
    box-shadow: 0px 0px 10px var(--color-contrast-high);
    border-radius: 10px;
    margin: 2.5rem 1.5rem;
    .header-card-title {
        font-weight: bold;
        font-size: 2rem;
        color: var(--color-bg);
        padding: 1.5rem;
        border-radius: 10px 10px 0 0;
    }
    .header-card-body {
        padding: 1.5rem;
    }
    &:nth-of-type(1) {
        .header-card-title {
            background-color: var(--color-success);
        }
    }
    &:nth-of-type(2) {
        .header-card-title {
            background-color: var(--color-primary);
        }
    }
    &:nth-of-type(3) {
        .header-card-title {
            background-color: var(--color-secondary);
        }
    }
}
</style>
