/**
    Shows the historic on-track rates for a school.
*/
<template>
    <h1 class="slide-header">{{ slide.title }}</h1>
    <h3>{{ slide.subtitle }}</h3>

    <table class="on-track-history-area">
        <tr>
            <td>
                <MyChart :chartConfig="chartConfig" :chartData="chartData" />
            </td>
            <td>
                <MyChart :chartConfig="barChartConfig" :chartData="chartData" />
            </td>
        </tr>
    </table>


    <div>
        <ul class="slide-questions">
            <li v-for="(question, index) in slide.questions" :key="index">
                {{ question }}
            </li>
        </ul>
    </div>
</template>

<script>
import MyChart from '@/components/charts.vue';

// This will be read from the school document

const dummyHistory = {
    '2019': { Q1: 90, Q2: 82, Q3: 83, Q4: 79, EOY: 88 },
    '2020': { Q1: 88, Q2: 78, Q3: 84, Q4: 61, EOY: 89 },
    '2021': { Q1: 70, Q2: 63, Q3: 67, Q4: 65, EOY: 77 },
    '2022': { Q1: 82, Q2: 75, Q3: 75, Q4: 71, EOY: 79 }
};

export default {
    props: {
        slide: Object
    },
    data() {
        return {
            chartData: dummyHistory,
            chartConfig: {
                type: 'onTrackLine'
            },
            barChartConfig: {
                type: 'onTrackBar'
            },
        };
    },
    components: { MyChart },
    methods: {}
}
</script>

<style lang="scss">
.on-track-history-area {
    width: 100%;
    td {
        width: 50%;
        padding: 10px;
    }
}
</style>
