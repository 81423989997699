/**
    On-track protocol: show on-track rates by sub-group.
*/
<template>
    <h1 class="slide-header">{{ slide.title }}</h1>
    <h3>{{ slide.subtitle }}</h3>
    <div class="slide-body">
        <CustomSelect :options="subGroups" title="" v-model="selectedSubGroup" />

        <div v-if="selectedSubGroup!=='Choose a sub-group'">
            <MyChart
                :chartConfig="addInteraction(chartConfig)"
                :chartData="store.state.studentData"
                :key="chartKey"
            />
        </div>

        <ul class="slide-questions">
            <li v-for="(question, index) in slide.questions" :key="index">
                {{ question }}
            </li>
        </ul>
    </div>
</template>

<script>
import MyChart from '@/components/charts.vue';
import { getRedirectCustomPropFromChart } from '@/functions/summary.js';
import CustomSelect from '@/components/customSelect.vue';

export default {
    props: {
        slide: Object
    },
    data() {
        return {
            chartKey: 0,
            chartConfig: {
                type: 'grouped',
                title: '',
                primaryField: this.config.fields.find(field => field.key === 'raceAndEthnicity.race'),
                secondaryField: this.config.fields.find(field => field.key === '_onTrackPlus'),
                stacked: true,
                showAsPercentage: true
            },
            subGroups: [
                {
                    displayName: 'Choose a sub-group',
                    fieldKey: ''
                },
                {
                    displayName: 'Race / Ethnicity',
                    fieldKey: 'raceAndEthnicity.race'
                },
                {
                    displayName: 'Gender',
                    fieldKey: 'gender'
                },
                {
                    displayName: 'ELL',
                    fieldKey: 'englishLearner'
                },
                {
                    displayName: 'SpEd',
                    fieldKey: 'specialEducation'
                },
            ],
            selectedSubGroup: 'Choose a sub-group'
        };
    },
    watch: {
        selectedSubGroup: function () {
            const mySubGroup = this.subGroups.find(element => element.displayName == this.selectedSubGroup)
            this.chartConfig.primaryField = this.config.fields.find(field => field.key == mySubGroup.fieldKey);
            this.chartKey++;
        }
    },
    components: { MyChart, CustomSelect },
    methods: {
        addInteraction(chartConfig) {
            chartConfig.onClick = this.chartClickHandler;
            return chartConfig;
        },
        chartClickHandler({filters}) {
            this.store.setCustomProp(getRedirectCustomPropFromChart({
                filters,
                redirectDefaultFields: ['_studentName', '_currentQuarterOnTrackStatus', 'gender', 'raceAndEthnicity.race', 'englishLearner', 'specialEducation'],
                fields: this.config.fields,
            }));

            this.$router.push('/tracker');
        },
    }
}
</script>

<style lang="scss">

</style>
