/**
    Matrix component. Shows a matrix that counts the number of students in the intersection of two category or numeric fields.
*/
<template>
    <div class="color-matrix">
        <table class="matrix-table">
            <tr>
                <th></th>
                <th :colspan="matrixConfig.columns.length">{{ matrixConfig.columnHeader }}</th>
            </tr>
            <tr class="matrix-header-row">
                <th class="row-header">{{ matrixConfig.rowHeader }}</th>
                <th 
                    v-for="(heading, index) in matrixConfig.columns" 
                    :key="index"
                    class="column-header"
                >
                    {{ heading }}
                </th>
            </tr>
            <tr 
                v-for="(row, i) in matrixConfig.rows" 
                :key="i"
                class="matrix-body-row"
            >
                <td class="row-header">{{ row }}</td>
                <td 
                    v-for="(col, j) in matrixConfig.columns" 
                    :key="j"
                    class="matrix-cell"
                    @click="addMatrixFilter(i, j)"
                    :style="getCellStyle()"
                    :class="matrixConfig.cellClasses[i][j]"
                >
                    {{ matrixConfig.cells[i][j] }}                    
                </td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    props: {
        matrixConfig: Object
    },
    emits: [ 'addMatrixFilter' ],
    methods: {
        getCellStyle() {
            return `
                width: ${(100 - 35) / (this.matrixConfig.columns.length)}%;
                height: 40px;
            `;
        },
        addMatrixFilter(i, j) {
            this.$emit('addMatrixFilter', {i, j});
        }
    },
}
</script>

<style lang="scss">
    @import '../assets/styles/colors.scss';
    .color-matrix {
        width: 100%;
        max-height: 600px;
        display: flex;
        flex-direction: column;
    }

    .matrix-chart-container {
        width: 100%;
        height: 20%;
        margin-bottom: 40px;
    }

    .matrix-table {
        height: 75%;
        .column-super-header {
            height:10px;
        }
        .matrix-header-row {
            height:10px;
        }
        .row-super-header {
            writing-mode: vertical-rl;
            width:2%;
        }
        .matrix-cell {
            text-align: center;
            border-radius: 3px;
            &:hover {
                cursor: pointer;
            }
        }
        .problem {
            color: var(--color-bg);
            background-color: var(--color-warning);
            &:hover {
                background-color: var(--color-warning-light);
            }
        }
        .warning {
            color: var(--color-contrast-full);
            background-color: var(--color-info);
            &:hover {
                background-color: var(--color-info-light);
            }
        }
        .success {
            color: var(--color-contrast-full);
            background-color: var(--color-grade-b);
            &:hover {
                background-color: var(--color-success-light);
            }
        }
        .high-success {
            color: var(--color-bg);
            background-color: var(--color-success);
            &:hover {
                background-color: var(--color-success-light);
            }
        }
        .row-header {
            text-align: right;
        }
    }
</style>
