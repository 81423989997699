/**
    Custom slide for on-track protocol, showing a matrix and questions.
*/
<template>
    <h1 class="slide-header">{{ slide.title }}</h1>
    <h3>{{ slide.subtitle }}</h3>

    <table class="ro-table">
        <tr>
            <td class="graph-col">
                <Matrix :matrixConfig="matrixConfig" @addMatrixFilter="addMatrixFilter"/>
            </td>
            <td class="legend-col">
                <div><span class="legend-block legend-success">&nbsp;</span>{{ slide.legend[0] }}</div>
                <div><span class="legend-block legend-gradeB">&nbsp;</span>{{ slide.legend[1] }}</div>
                <div><span class="legend-block legend-info">&nbsp;</span>{{ slide.legend[2] }}</div>
                <div><span class="legend-block legend-warning">&nbsp;</span>{{ slide.legend[3] }}</div>
            </td>
        </tr>
    </table>

    <div>
        <ul class="slide-questions">
            <li v-for="(question, index) in slide.questions" :key="index">
                {{ question }}
            </li>
        </ul>
    </div>
</template>

<script>
import Matrix from '@/components/matrix.vue';
import { getMatrixCells, getMatrixFilters } from '@/functions/matrix.js';
import { getInsightMatrix } from '@/functions/insight.js';

export default {
    props: {
        slide: Object
    },
    data() {
        return {
            matrixConfig: getInsightMatrix({
                data: this.store.state.studentData,
                config: this.slide.matrixConfig,
                fields: this.config.fields
            })
        };
    },
    methods: {
        getMatrix(config) {
            config.cells = getMatrixCells({config, data: this.store.state.studentData, fields: this.config.fields});
            return config;
        },
        addMatrixFilter({i, j}) {
            const myMatrixFilters = getMatrixFilters({
                config: matrixConfig,
                fields: this.config.fields,
                i, j
            });

            const fieldList = ['_studentName', 'passingCourseCount', 'ADA', 'gender', 'raceAndEthnicity.race', 'englishLearner', 'specialEducation'];            

            this.store.setCustomProp({
                filters: myMatrixFilters,
                fields: fieldList
                    .map(f => this.config.fields.find(field => field.key == f)?.displayName)
                    .filter(f => f !== undefined),
                columnLayout: fieldList.map(f => ({field: f}))
            });

            this.$router.push('/tracker');

        }
    },
    components: { Matrix }
}
</script>

<style lang="scss">

.ro-table {
    .graph-col {
        .matrix-cell {
            width: 100px !important;
            padding: 20px 0;
        }
        .row-header {
            width: 100px !important;
            text-align: center;
        }
        .matrix-header-row {
            height: 50px;
        }
    }
    .legend-col {
        width: 450px;
        padding: 20px;
        .legend-block {
            display: inline-block;
            width: 20px;
            margin: 3px 8px;
        }
        .legend-warning {
            background-color: var(--color-warning);
        }
        .legend-info {
            background-color: var(--color-info);
        }
        .legend-gradeB {
            background-color: var(--color-grade-b);
        }
        .legend-success {
            background-color: var(--color-success);
        }
    }
}
</style>
