/**
 * This file contains the functions related to building and displaying custom data matrices.
 */
const applyFilter = (arr, filter) => {
    return arr.filter(record => {
        let fieldKeys = null;
        const isSplit = filter.field.key.includes('.');

        if (isSplit) {
            fieldKeys = filter.field.key.split('.');
        }

        let value = null;

        if (filter.field.logField) {
            value = record[filter.field.key][0].value;
        } else if (isSplit) {
            value = record[fieldKeys[0]][fieldKeys[1]];
        } else {
            value = record[filter.field.key];
        }

        if (filter.lt) {
            if (value >= filter.lt) return false;
        }
        if (filter.gte) {
            if (value < filter.gte) return false;
        }
        if (filter.eq) {
            if (value !== filter.eq) return false;
        }
        if (value === null) {
            return false;
        }
        return true;
    });
};

const getCount = ({i, j, rowFilters, columnFilters, data}) => {
    let result = applyFilter([...data], rowFilters[i]);
    result = applyFilter(result, columnFilters[j]);
    return result.length;
};

const buildFilter = (obj, field) => {
    const blankFilter = {
        eq: null, gte: null, lt: null
    };
    obj.field = field;
    return {...blankFilter, ...obj};
};

const getFilterObjs = (config, fields) => {

    const columnField = fields.find(field => field.key == config.columnFieldKey);
    const rowField = fields.find(field => field.key == config.rowFieldKey);

    const rowFilters = config.rowFilters.map(e => buildFilter(e, rowField));
    const columnFilters = config.columnFilters.map(e => buildFilter(e, columnField));

    return [rowFilters, columnFilters];
};

const getFilters = filter => {
    let result = [];

    [
        {key: 'lt', type: '<', displayType: '<'},
        {key: 'gte', type: '>=', displayType: '≥'},
        {key: 'eq', type: 'customEquals', displayType: ':'},
    ].forEach(filterType => {
        if (filter[filterType.key]) {
            result.push({        
                displayName: filter.field.displayName,
                field: filter.field.key,
                type: filterType.type,
                displayType: filterType.displayType,
                value: filter[filterType.key]
            });
        }    
    });

    return result;
};

export const getMatrixCells = ({
    config, data, fields
}) => {
    const [rowFilters, columnFilters] = getFilterObjs(config, fields);

    return Array.from(Array(config.rows.length))
    .map((e, i) => {
        return Array.from(Array(config.columns.length))
        .map((f, j) => {
            return getCount({i, j, rowFilters, columnFilters, data});
        })
    });
};

export const getMatrixFilters = ({
    config, fields, i, j
}) => {
    const [rowFilters, columnFilters] = getFilterObjs(config, fields);

    const returnRowFilters = getFilters(rowFilters[i]);
    const returnColumnFilters = getFilters(columnFilters[j]);

    return [].concat(returnRowFilters).concat(returnColumnFilters);
};

export const getMatrixChart = ({
    config,
}) => {

    const chartConfig = {
        type: 'matrix',
        title: 'Performance Groups',
        labels: config.labels
    };

    const myCount = {
        problem: 0, warning: 0, success: 0, 'high-success': 0
    };

    config.cells.forEach((row, i) => {
        row.forEach((value, j) => {
            myCount[config.cellClasses[i][j]] += value;
        });
    });

    const data = ['problem', 'warning', 'success', 'high-success'].map(element => myCount[element]);

    return {
        config: chartConfig,
        data, 
    };
};
